.elasticity[data-v-22b9dfe6] {
  display: flex;
  align-items: center;
}
.el-icon-picture-outline[data-v-22b9dfe6] {
  font-size: 100px;
  color: #c0c4cc;
}
.addIcon[data-v-22b9dfe6] {
  margin-left: 20px;
  font-size: 22px;
  cursor: pointer;
}
.el-input[data-v-22b9dfe6] {
  width: 50%;
}
.demo-ruleForm[data-v-22b9dfe6] {
  margin-top: 30px;
}
.compile[data-v-22b9dfe6] {
  color: #409eff;
  font-weight: bold;
}
